module.exports = {
  messages: {
    CRATE_ANSCHREIBEN_STARTPAGE_3_STEPS_HEADLINE:
      'In nur 4 Schritten zum professionellen Anschreiben',
    CRATE_ANSCHREIBEN_STARTPAGE_STEP_1:
      'Gib Deine persönlichen Infos an und wo Du Dich bewirbst',
    CRATE_ANSCHREIBEN_STARTPAGE_STEP_2: 'Lass Deinen Text mit KI erstellen',
    CRATE_ANSCHREIBEN_STARTPAGE_STEP_3: 'Wähl Dein Design und Farbschema aus',
    CRATE_ANSCHREIBEN_STARTPAGE_STEP_4: 'Lad Dein Anschreiben einfach herunter',
    CRATE_ANSCHREIBEN_STARTPAGE_SUBTITLE:
      'Überzeug mit Deinem professionellen Anschreiben und heb Deine Stärken hervor. In nur wenigen Klicks und mit optionalen KI-basierten Textbausteinen. Ganz einfach und schnell.',
    CRATE_ANSCHREIBEN_STARTPAGE_TITLE:
      'Erstell Dir Dein Anschreiben kostenlos – oder lass KI ran',
    CRATE_ANSCHREIBEN_STARTPAGE_TOP_ARTICLES_HEADLINE:
      'Tipps von Expert·innen fürs überzeugende Anschreiben',
    CRATE_ANSCHREIBEN_STARTPAGE_TOP_ARTICLES_SUBLINE:
      'Klar, Du kannst es natürlich nach den klassischen Regeln der Kunst machen. Das ist nicht verkehrt. Bevor Du’s tust, lass Dich zuerst von unseren Artikeln inspirieren. Dann entscheide, wie Du die Geschichte Deiner beruflichen Laufbahn erzählen willst. Damit sie auch in den Köpfen der Recruiter·innen und Personaler·innen hängen bleibt. Viel Erfolg und erstmal viel Spaß beim Lesen.',
    CRATE_LEBENSLAUF_STARTPAGE_3_STEPS_HEADLINE:
      'In nur 3 Schritten zum perfekten Lebenslauf',
    CRATE_LEBENSLAUF_STARTPAGE_3_STEPS_SUBLINE:
      "Alles, was Du brauchst: Viele Profi-Designs, jederzeit nach Deinen Wünschen anpassbar. Das geht einfach, eben Schritt für Schritt. Und ganz nebenbei sorgst Du so für mehr Einladungen zum Job-Interview. Am Ende einfach den fertigen Lebenslauf runterladen, das war's. Und so geht’s:",
    CRATE_LEBENSLAUF_STARTPAGE_APPS_HEADLINE:
      'Und? Bereit für die neue XING App?',
    CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_HEADLINE:
      "Hier gibt's ein paar Tipps zu Deiner Bewerbung",
    CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE: 'Weiterlesen',
    CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_SUBLINE:
      'Mit den Tipps von Expert·innen weißt Du, worauf\'s heute beim Zusammenstellen Deiner Bewerbung ankommt. Antworten gibt\'s hier auf die Fragen "Wie soll eine Bewerbungsmappe aussehen?" oder "Brauch ich unbedingt ein Motivationsschreiben?". Und vor allem: "Welche Formulierungen punkten bei Personaler·innen?" Viel Spass beim Lesen!',
    CRATE_LEBENSLAUF_STARTPAGE_COVER_LETTER_CTA:
      'Anschreiben von KI erstellen lassen',
    CRATE_LEBENSLAUF_STARTPAGE_COVER_LETTER_HEADLINE:
      'KI schreibt Dein Anschreiben: Du fragst Dich, ob Künstliche Intelligenz (KI) das kann? Probier’s aus.',
    CRATE_LEBENSLAUF_STARTPAGE_COVER_LETTER_SUBLINE:
      'Es kann Stunden dauern, bis ein Anschreiben erstellt ist. KI hilft Dir dabei, Deine Fähigkeiten und berufliche Laufbahn so darzustellen, dass sie auf die Stelle passen, für die Du Dich bewirbst. \nGib Deine Details und die Stellenbeschreibung ein und wähl ein Design und erhalte in wenigen Minuten Dein Anschreiben.',
    CRATE_LEBENSLAUF_STARTPAGE_CTA: 'Jetzt kostenlos gestalten',
    CRATE_LEBENSLAUF_STARTPAGE_DREAM_JOB_HEADLINE:
      'Mit Deinem Lebenslauf zu dem Job, der gut zu Dir passt.',
    CRATE_LEBENSLAUF_STARTPAGE_DREAM_JOB_SUBLINE:
      'Je professioneller Du Deinen Lebenslauf gestaltest, desto höher die Wahrscheinlichkeit, von Top-Unternehmen eingeladen zu werden. Du brauchst nur wenige Klicks. Alles auf einer Seite und vor allem einfach zu bedienen.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_1:
      'Such zuerst aus vielen Profi-Vorlagen das Design aus, welches gut zu Deiner Persönlichkeit passt.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_1_HEADLINE:
      'Aus professionellen Vorlagen auswählen',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_1_SUBLINE:
      'Unsere Vorlagen haben Bewerbungs-Profis entwickelt. Sie wissen, was heute bei Recruiter•innen gefragt ist.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_2:
      'Gib dann Deine persönlichen Infos ein und pass auf Wunsch noch mal Design oder Farben an.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_2_HEADLINE:
      'Die richtigen Farben aussuchen',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_2_SUBLINE:
      'Gibt Deinem Lebenslauf die Farbe, die gut zu Dir passt. So bekommt das Unternehmen schon beim ersten Blick einen guten Eindruck von Dir.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_3:
      'Fast fertig. Lad jetzt Deinen Lebenslauf auf Deinen Rechner. Du entscheidest, in welchem Format.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_3_HEADLINE: 'Intelligentes Wording nutzen',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_3_SUBLINE:
      'Welche Sprache sprechen Recruiter•innen und welche Begriffe wollen sie lesen? Wir helfen Dir gern mit Vorschlägen. Dafür vergleichen wir Deinen Job-Titel mit den Sucheingaben aus unserer Datenbank.',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_4_HEADLINE:
      'Den Lebenslauf immer dabei haben',
    CRATE_LEBENSLAUF_STARTPAGE_STEP_4_SUBLINE:
      'Damit Du Deinen Lebenslauf immer parat hast, lädst Du ihn am besten direkt auf Deinen Rechner. So kannst Du Dich ruckzuck bewerben und verpasst nicht die Chance auf Deinen Traum-Job.',
    CRATE_LEBENSLAUF_STARTPAGE_SUBTITLE:
      'Hol Dir den Job, der zu Dir passt. Mit Deinem professionellen Lebenslauf erhöhst Du Deine Chancen auf Job-Interviews bei Top-Unternehmen.',
    CRATE_LEBENSLAUF_STARTPAGE_TITLE: 'Dein Lebenslauf als PDF',
    CRATE_LEBENSLAUF_STARTPAGE_TOP_ARTICLES_HEADLINE:
      'Mit Top-Artikeln zum allerbesten Lebenslauf',
    CRATE_LEBENSLAUF_STARTPAGE_TOP_ARTICLES_SUBLINE:
      'Klar, Du kannst es natürlich nach den klassischen Regeln der Kunst machen. Das ist nicht verkehrt. Bevor Du’s tust, lass Dich zuerst von unseren Artikeln inspirieren. Dann entscheide, wie Du die Geschichte Deiner beruflichen Laufbahn erzählen willst. Damit sie auch in den Köpfen der Recruiter·innen und Personaler·innen hängen bleibt. Viel Erfolg und erstmal viel Spaß beim Lesen.',
    CRATE_LEBENSLAUF_STARTPAGE_YOUR_WISHES_HEADLINE:
      'Gestalte Deinen Lebenslauf nach Deinen Wünschen',
    CRATE_LEBENSLAUF_STARTPAGE_YOUR_WISHES_SUBLINE:
      'Du konzentrierst Dich auf die Inhalte, wir liefern das Design. Klingt nach nem Deal, oder? Nicht vergessen: Neben Inhalten kommt’s nämlich auch auf die Gestaltung an. Wir begleiten Dich durch den gesamten Prozess.',
    CRATE_LEBENSLAUF_XING_STARTPAGE_TITLE:
      'Erstelle Dir ganz einfach Deinen Lebenslauf',
    CRATE_VITA_STARTPAGE_NEXT_TEMPLATE_BUTTON: 'Nächste Vorlage',
    CRATE_VITA_STARTPAGE_PREVIOUS_TEMPLATE_BUTTON: 'Letzte Vorlage',
    CRATE_VITA_STARTPAGE_TURNTABLE_CTA: 'Diese Vorlage nutzen',
    LOSP_SEO_SECTION_COMPANIES_DIRECTORY: 'Unternehmen von A bis Z',
    LOSP_SEO_SECTION_HEADER_DIRECTORIES: 'Verzeichnisse',
    LOSP_SEO_SECTION_HEADER_JOB_ROLES: 'Positionen',
    LOSP_SEO_SECTION_HEADER_TOP_LOCATIONS: 'Top-Standorte',
    LOSP_SEO_SECTION_HEADER_TOP_SKILLS: 'Top-Fähigkeiten',
    LOSP_SEO_SECTION_JOBS_DIRECTORY: 'Jobs von A bis Z',
    LOSP_SEO_SECTION_PEOPLE_DIRECTORY: 'Mitglieder von A bis Z',
    QR_CODE_BANNER_SUBTITLE:
      'und Du hast Deinen nächsten Job quasi schon in der Tasche.',
    QR_CODE_BANNER_TITLE: 'Hol Dir die XING App',
    SEO_SECTION_TITLE: 'Mehr Inspiration für Deine Job-Suche',
  },
};
